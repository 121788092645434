<script lang="ts">
  export let message: ChatMessage;
  export let disabled = false;

  import FractalBotIcon from '$lib/assets/icons/fractal-bot-icon.svg';
  import UserIcon from '$lib/assets/icons/user-icon.svg';
  import MarkdownPreview from '$lib/components/generic/MarkdownPreview.svelte';
  import projectBoardStore from '$lib/stores/projectBoardStore';
  import userStore from '$lib/stores/userStore';
  import type { ChatMessage } from '$lib/types/message';
  import type { User } from '$lib/types/response';
  import { AI_MESSAGE_TYPE } from '$lib/utils/constants';
  import { isSmallScreen } from '$lib/utils/window';
  import { onMount } from 'svelte';
  import FeedbackOptions from './FeedbackOptions.svelte';
  import MessageOptions from './MessageOptions.svelte';

  let fromBot = message.type === AI_MESSAGE_TYPE;

  let isDarkTheme = false;
  let isSmallComponent = isSmallScreen();

  $: isSmallComponent ||= $projectBoardStore.isProjectBoard;

  onMount(() => {
    isDarkTheme = document.documentElement.classList.contains('dark');
  });

  const getDisplayName = () => {
    if ($userStore && $userStore.user) {
      return ($userStore.user as User)?.first_name ?? 'You';
    }
    return 'You';
  };
</script>

<div class="min-h-fit flex flex-col pl-4 py-2 rounded-xl my-[8px] {isSmallComponent ? 'pr-3' : 'pr-10 '}">
  <div class="flex flex-row mt-1 w-full justify-between">
    <div class="flex flex-row items-center">
      {#if fromBot}
        <img src={FractalBotIcon} alt="sym bot" class="self-start mr-5" width="27px" height="27px" />
      {:else}
        <img src={UserIcon} alt="sender-icon" class="self-start mr-5 rounded-full" width="27px" height="27px" />
      {/if}
      <h1 class="font-bold text-base/[18px] mr-2 {fromBot && isSmallComponent ? 'pt-1' : ''}">
        {fromBot ? 'Sym' : getDisplayName()}
      </h1>
    </div>
    {#if !$projectBoardStore.isProjectBoard}
      <div class="flex flex-row items-center">
        <MessageOptions bind:message on:feedbackChange on:delete {disabled} />
      </div>
    {/if}
  </div>
  <div class="flex flex-col ml-12 max-width-calc(100% - 2.5rem) width-calc(100% - 2.5rem)">
    <p
      class="prose lg:prose-md prose-chat {isSmallComponent ? 'markdown-mobile ' : 'markdown'} break-words"
      class:prose-dark-chat={isDarkTheme}
    >
      {#if message.message === '' && fromBot === true}
        <div class="text-gray-500 mt-5">Loading...</div>
      {:else}
        {#key message.message}
          <MarkdownPreview content={message.message} />
        {/key}
      {/if}
    </p>
    {#if isSmallComponent && fromBot && !$projectBoardStore.isProjectBoard}
      <div class="flex flex-row items-center ml-auto mt-3 mr-1">
        <FeedbackOptions bind:message on:feedbackChange {disabled} />
      </div>
    {/if}
  </div>
</div>

<style>
  .markdown {
    min-width: 100%;
  }
  .markdown-mobile {
    max-width: 75vw;
  }
  :global(.prose-dark-chat) {
    color: rgb(209 213 219 / 1);
  }
  :global(.prose-dark-chat p code) {
    color: white;
  }
  :global(.prose-chat pre) {
    background-color: var(--color-ai8-sym-chat-light-gray);
    margin: 0;
    padding: 0;
    border: 1px solid lightgray;
  }
  @media (prefers-color-scheme: dark) {
    :global(.prose-chat pre) {
      background-color: var(--color-ai8-chat-gray);
    }
  }
  :global(.prose-chat pre .copy-button) {
    color: black !important;
  }
</style>
